@keyframes ldio-ma5sgw1hvbe {
    0% { transform: rotate(0) }
    100% { transform: rotate(360deg) }
  }
  .ldio-ma5sgw1hvbe div { box-sizing: border-box!important }
  .ldio-ma5sgw1hvbe > div {
    position: absolute;
    width: 72px;
    height: 72px;
    top: 14px;
    left: 14px;
    border-radius: 50%;
    border: 8px solid #000;
    border-color: #ffffff transparent #ffffff transparent;
    animation: ldio-ma5sgw1hvbe 1s linear infinite;
  }
  .ldio-ma5sgw1hvbe > div:nth-child(2) { border-color: transparent }
  .ldio-ma5sgw1hvbe > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
  }
  .ldio-ma5sgw1hvbe > div:nth-child(2) div:before, .ldio-ma5sgw1hvbe > div:nth-child(2) div:after { 
    content: "";
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    top: -8px;
    left: 24px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 64px 0 0 #ffffff;
  }
  .ldio-ma5sgw1hvbe > div:nth-child(2) div:after { 
    left: -8px;
    top: 24px;
    box-shadow: 64px 0 0 0 #ffffff;
  }
  .loadingio-spinner-dual-ring-2gao2qa5lot {
    width: 30px;
    height: 30px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-ma5sgw1hvbe {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.3);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-ma5sgw1hvbe div { box-sizing: content-box; }