.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #000;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  height: calc(100vh - 40px);
  width: calc(100% - 40px);
}

.logo {
  max-width: 350px;
  cursor: pointer;
}

.filterTitle {
  font-size: 25px;
  color: #fff;
  margin: 30px;
  margin-top: 50px;
  font-weight: 700;
}

.filterContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #aaa;
  font-size: 16px
}

.filterItem {
  flex: 1;
  margin-left: 20px;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.selectedSourcesContainer,
.selectedRegionsContainer,
.selectedTagsContainer {
  border: 1px solid #aaa;
  padding: 0 20px 0 40px;
  border-radius: 10px;
}

.searchQueryContainer {
  width: 25vw;
  position: relative;
  border: 1px solid #aaa;
  display: flex;
  border-radius: 10px;
}

.searchQueryInput {
  width: 100%;
  background: transparent;
  color: #aaa;
  font-size: 16px;
  padding: 0 20px 0 40px;
  border: 0
}

.searchQueryInput::placeholder {
  color: #aaa
}

.searchQueryInput:focus {
  outline: none
}

.iconFilter {
  position: absolute;
  left: 10px;
  top: 12px
}

.resetFiltersButton {
  background-color: #21aeef;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 0 0 20px;
}

.buttonFilter {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 0;
  margin: 0;
  padding: 12px;
  background-color: #21aeef;
  border-radius: 8px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.filterListItem {
  color: #fff;
  border-bottom: 1px solid #aaa;
  padding-bottom: 10px; 
  margin-top: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.filterListItem span {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.buttonFilterDialog {
  background-color: #fe7200;
  margin: 0;
  text-align: center;
  padding: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
}

.searchItems {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #aaa;
  align-items: center;
}

.searchItems input {
  font-size: 16px;
  background-color: transparent;
  border: 0;
  color: #aaa;
  padding: 10px 20px;
  font-style: italic;
  display: inline-block;
  width: 100%
}

.searchItems input:focus {
  outline: none;
}

.searchItems input::placeholder {
  color: #aaa;
  font-style: italic;
}

.selectedFilterHorizontal {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  white-space: nowrap
}

.selectedFilterHorizontal .selectedItem {
  background-color: #21aeef;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin: 10px 10px 10px 0;
  border-radius: 5px;
  cursor: pointer;
}

.newsContainer {
  width: 100%;
}

.newsContent {
  background: #343434;
  border-radius: 10px;
  cursor: pointer;
}

.newsContent img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.titleNews{
  margin: 0;
  padding: 10px;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  font-family: "Barlow", sans-serif;
}

.metaNews {
  margin: 0;
  padding: 0 10px 10px 10px;
  color: #cecece;
}

.masonryGrid {
  width: calc(100vw - 40px);
  margin-top: 50px;
    padding-right: 0px;
    padding-left: 0px;
    display: grid;
    justify-content: flex-start;
    align-self: stretch;
    float: none;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(23vw, 1fr));
    position: relative;
    grid-auto-rows: 0;
    grid-template-rows: masonry
}

.newsTags {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.newsTags span {
  border: 1px solid #21aeef;
  border-radius: 5px;
  text-transform: uppercase;
  color: #21aeef;
  padding: 5px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
}

.newsSummary {
  padding: 0 10px 10px 10px;
  color: #cecece;
  font-size: 14px;
  margin-top: 0 
} 

.shareNews {
  margin: 10px 10px 0 10px;
  padding: 10px 0;
  border-top: 1px solid #cecece
}

.socialIcon {
  font-size: 30px !important;
  color: #fff;
  margin-right: 12px
}

.filterButton {
  position: absolute;
  margin: 3px;
  padding: 12px 20px;
  right: 0;
  background: #21aeef;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.loaderIcon {
  position: absolute;
  right: 10px;
  top: 10px
}

.goTopButton {
  position: fixed;
  width: 50px;
  height: 50px;
  background: #21aeef;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 20px;
  border-radius: 25px;
  color: #fff;
  opacity: 0.6;
  cursor: pointer
}

.goTopButton:hover {
  opacity: 1;
}

.goTopIcon {
  font-size: 40px !important;
}

@media screen and (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .logo {
    max-width: 80vw;
  }
  .filterTitle {
    text-align: center;
  }
  .newsContainer {
    margin-bottom: 20px;
  }
  .filterContainer {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .searchQueryContainer {
    width: 100%;
    height: 52px;
  }
  .searchQueryContainer,
  .filterItem {
    margin: 10px 0
  }
  .resetFiltersButton {
    padding: 15px
  }
  #scroller {
    max-width: 90vw;
  }
}